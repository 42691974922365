import { useState, useMemo } from "react";

// css
import "./index.less";

// api
import { GetStoreBGoods } from "api/store";

const ContShopLeft = (props) => {
  const { store_id } = props;

  const [storeGoodsNew, setStoreGoodsNew] = useState([]);

  useMemo(() => {
    // 店铺新品
    const getStoreBGoods = async () => {
      const res = await GetStoreBGoods({ store_id });
      setStoreGoodsNew(res?.datas || []);
    };

    if (store_id) getStoreBGoods();
  }, [store_id]);

  return (
    <div className="stores-goods">
      {/* 店铺新品 */}
      <h3 className="store-goods-title">店铺爆款花型</h3>
      <div className="store-goods-main">
        {storeGoodsNew?.map?.((k, i) => (
          <a
            target="blank"
            href={`/merchandise/${k.goods_id}?is_b=1`}
            className="store-goods-item"
            key={i}
          >
            <img className="goods-img" src={k.goods_images} alt="" />
            <div className="goods-name">{k.goods_name}</div>
          </a>
        ))}
      </div>
    </div>
  );
};
export default ContShopLeft;
