import { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { Image } from 'antd'
const Images = (props) => {
  const { src, width, height, preview = false } = props;
  const [src1, setSrc1] = useState("");
  useEffect(() => {
    if (src) {
      setSrc1(() => {
        let str = "";
        if(width<300){
          if (src.indexOf("fangzhifu") > -1 && height && width) {
            str =
              src +
              "?x-oss-process=image/resize,m_lfit,h_" +
              height * 2 +
              ",w_" +
              width * 2;
          }
          if (src.indexOf("zhaojiafang") > -1 && height && width) {
            str =
              src +
              "?imageView2/1/w/" +
              width * 2 +
              "/h/" +
              height * 2 +
              "/ignore-error/1/_58*58";
          }
        }
        if(width<50){
          if (src.indexOf("fangzhifu") > -1 && height && width) {
            str =
              src +
              "?x-oss-process=image/resize,m_lfit,h_" +
              height * 2 +
              ",w_" +
              width * 2;
          }
          if (src.indexOf("zhaojiafang") > -1 && height && width) {
            str =
              src +
              "?imageView2/1/w/" +
              width * 2 +
              "/h/" +
              height * 2 +
              "/ignore-error/1/_58*58";
          }
        }
        if (!str) str = src;
        return str;
      });
    }
  }, [src]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <LazyLoad
      offset={400}
      // placeholder={
      //   <Image preview={false} src='https://osss.fangzhifu.com/zyt.jpeg' width={width} height={height} />
      // }
    >
      <Image
        className="centerImg"
        preview={preview}
        src={src1}
        width={width}
        height={height}
        // placeholder={
        //   <Image preview={false} src='https://osss.fangzhifu.com/zyt.jpeg' width={width} height={height} />
        // }
        // fallback="https://osss.fangzhifu.com/zyt.jpeg?0"
      ></Image>
    </LazyLoad>
  );
};
export default Images;