import http from "utils/request";
import payhttp from "utils/payrequest";

export const GoodsDetailsSpu = (params = {}) => {
  return http.get("/api/wap/goods_details_spu", { params });
};
export const GoodsDetailsSpuNow = (params = {}) => {
  return http.get("/api/wap/goods_details_spu_now", { params });
};
export const GoodsDetailsSku = (params = {}) => {
  return http.get("/api/wap/goods_details_sku", { params });
};

// 私域-ip
export const GetIp = (params = {}) => {
  return http.get("/api/wap/get_ip", { params });
};
export const SetIp = (params = {}) => {
  return http.post("/api/wap/set_ip", params);
};


// topBanner
export const GoodsDetails = (params = {}) => {
  return http.get("/api/wap/goods_details", { params });
};

// topBanner
export const StoreGoods = (params = {}) => {
  return http.get("/api/wap/store_goods", { params });
};
export const GoodsVolume = (params = {}) => {
  return http.get("/api/wap/goods_volume", { params });
};
export const GoodsDownload = (params = {}) => {
  return http.get("/api/wap/goods_download", { params });
};
// Cart
export const GetCart = (params = {}) => {
  return http.get("/api/member/get_cart", { params });
};
export const addCart = (params = {}) => {
  return http.post("/api/member/add_cart", params );
};
export const SaveCart = (params = {}) => {
  return http.post("/api/member/save_cart", params);
};
export const AddAllCart = (params = {}) => {
  return http.post("/api/member/add_all_cart", params);
};
export const DelCart = (params = {}) => {
  return http.post("/api/member/del_cart", params);
};
export const GetCartCount = (params = {}) => {
  return http.get("/api/member/get_cart_count", { params });
};
// Buy
// export const GetBuy = (params = {}) => {
//   return http.get("/api/member/get_buy", { params });
// };
export const GetBuy = (params = {}) => {
  return http.get("/api/member/get_df_buy", { params });
};
// Buy
export const AddBuy = (params = {}) => {
  return http.post("/api/member/add_buy", params );
};

//地址识别
export const AreaAuto = (params = {}) => {
  return http.post("/api/common/area_auto", params);
};
// 默认地址
export const GetAddressInfo = (params = {}) => {
  return http.get("/api/member/get_address_info", { params });
};
// 收货地址
export const GetAddress = (params = {}) => {
  return http.get("/api/member/get_address", { params });
};
// 添加收货地址
export const AddAddress = (params = {}) => {
  return http.post("/api/member/add_address", params);
};
// 更新收货地址
export const SaveAddress = (params = {}) => {
  return http.post("/api/member/save_address", params);
};
// 删除收货地址
export const DelAddress = (params = {}) => {
  return http.post("/api/member/del_address", params);
};
// sender
// 默认地址
export const GetSenderInfo = (params = {}) => {
  return http.get("/api/member/get_sender_info", { params });
};
// 收货地址
export const GetSender = (params = {}) => {
  return http.get("/api/member/get_sender", { params });
};
// 添加收货地址
export const AddSender = (params = {}) => {
  return http.post("/api/member/add_sender", params);
};
// 更新收货地址
export const SaveSender = (params = {}) => {
  return http.post("/api/member/save_sender", params);
};
// 删除收货地址
export const DelSender = (params = {}) => {
  return http.post("/api/member/del_sender", params);
};

	

// 平台
export const TransportExpress = (params = {}) => {
  return http.get("/api/wap/transport_express", { params });
};
// 平台
export const TransportChannel = (params = {}) => {
  return http.get("/api/wap/transport_channel", { params });
};
// 物流
export const StoreTransport = (params = {}) => {
  return http.get("/api/wap/store_transport", { params });
};
// 地址 
export const Area = (params = {}) => {
  return http.get("/api/common/area", { params });
};
// 支付
export const GetPay = (params = {}) => {
  return http.post("/api/member/get_pay", params );
};
export const AddAllPay = (params = {}) => {
  return payhttp.post("/api/wap/add_all_pay", params );
};
export const OrderPay = (params = {}) => {
  return http.post("/api/member/order_pay", params);
};
// order
export const CopyOne = (params = {}) => {
  return http.get("/api/member/copy_one", { params });
};
export const GetOrder = (params = {}) => {
  return http.get("/api/member/order", { params });
};
export const GetOrderInfo = (params = {}) => {
  return http.get("/api/member/order_info", { params });
};
export const GetOrderLog = (params = {}) => {
  return http.get("/api/member/order_log", { params });
};
export const GetOrderExpress = (params = {}) => {
  return http.get("/api/member/order_express", { params });
};
export const CancelOrder = (params = {}) => {
  return http.post("/api/member/order_cancel", params );
};
export const DelOrder = (params = {}) => {
  return http.post("/api/member/order_del", params);
};
export const ConfirmOrder = (params = {}) => {
  return http.post("/api/member/order_confirm", params);
};
export const AllOrderPay = (params = {}) => {
  return http.post("/api/member/all_order_pay", params);
};
export const OrderCount = (params = {}) => {
  return http.get("/api/member/order_count", {params});
};
export const OrderExcle = (params = {}) => {
  return http.get("/api/member/order_excel", { params });
};
// refund
export const GetRefund = (params = {}) => {
  return http.get("/api/member/get_refund", { params });
};
export const GetRefundCount = (params = {}) => {
  return http.get("/api/member/get_refund_count", { params });
};
export const GetRefundInfo = (params = {}) => {
  return http.get("/api/member/get_refund_info", { params });
};
export const GetRefundGoods = (params = {}) => {
  return http.get("/api/member/get_refund_goods", {params} );
};
export const AddRefund = (params = {}) => {
  return http.post("/api/member/add_refund", params);
};
export const SaveRefund = (params = {}) => {
  return http.post("/api/member/save_refund", params);
};
export const GetReturnAddress = (params = {}) => {
  return http.get("/api/member/get_return_address", { params });
};
export const Express = (params = {}) => {
  return http.get("/api/wap/express", { params });
};

export const ImgClick = (params = {}) => {
  return http.post("/api/wap/img_click", params);
};
// 点击事件
export const AddGoodsDownload = (params = {}) => {
  return http.post("/api/wap/add_goods_download", params);
};

// 供应商入驻
export const Settled = (params = {}) => {
  return http.post("/api/wap/settled", params );
};
// 商品二维码
export const GoodsCodeMall = (params = {}) => {
  return payhttp.get("/api/wap/goods_code_mall", { params });
};

// 图片下载
export const GetDown = (params = {}) => {
  return payhttp.get("/api/get_down", { params });
};
export const ImgDownUrl = (params = {}) => {
  return payhttp.get("/api/img_down_url", { params });
};
export const VideoDownUrl = (params = {}) => {
  return payhttp.get("/api/video_down_url", { params });
};

// 包装
export const GetDfGoods = (params = {}) => {
  return http.get("/api/member/get_df_goods", { params });
};
export const GetDfClass = (params = {}) => {
  return http.get("/api/member/get_df_class", { params });
};
export const GetDfPrice = (params = {}) => {
  return http.get("/api/member/get_df_price", { params });
};
// 商品补差价
export const GetRefundPrice = (params = {}) => {
  return payhttp.get("/api/member/get_refund_price", { params });
};
  // 运费补差价
export const GetFeePrice = (params = {}) => {
  return payhttp.get("/api/member/get_free_price", { params });
};
  // 运费补差价
export const GetMemberOrderPrice = (params = {}) => {
  return payhttp.get("/api/member/get_member_order_price", { params });
};


// 快速下图
export const GoodsZipImages = (params = {}) => {
  return http.post("/api/wap/goods_zip_images", params);
};
// 视频下载
export const GoodsZipVideo = (params = {}) => {
  return http.post("/api/wap/goods_zip_video", params);
};

// 图片合集
export const GoodsImagesVideoV1 = (params = {}) => {
  return http.get("/api/wap/goods_images_video_v1", { params });
};

// 视频点击
export const VideoClick = (params = {}) => {
  return http.post("/api/wap/video_click", params);
};

// 获取微信链接
export const WxGoodsUrl = (params = {}) => {
  return http.post("/api/common/wx_goods_url", params);
};


// 浏览量
export const GoodsClick = (params = {}) => {
  return http.get("/api/wap/goods_click", { params });
};

// 提交商品反馈
export const GoodsFeedback = (params = {}) => {
  return http.post("/api/wap/goods_feedback", params);
};

// 加入采购单
export const AddCjGoods = (params = {}) => {
  return http.post("/api/member/add_cj_goods", params);
};


export const GetPriceDifferenceList = (params = {}) => {
  return http.get("/api/member/get_price_difference_list", { params });
};
export const GetPriceDifferenceTotal = (params = {}) => {
  return http.get("/api/member/get_price_difference_total", { params });
};


// 帮我申请优惠价
export const ApplicationPrice = (params = {}) => {
  return http.post("/api/member/application_price", params);
};